
import Vue from 'vue'
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import fetcher from '@/services/fetcher'

type Responsavel = {
  NOME: string;
  INSTAGRAM: string;
  CELULAR: string;
};

type Cliente = {
  CODCLI: number;
  CLIENTE: string;
  TELEFONE: string;
  EMAIL: string;
};

type DadosFormulario = {
  retorno: boolean;
  mensagem: string;
  cliente: Cliente;
  responsavel: Responsavel;
  codigoVerificacao?: number;
};

type RetornoMsgVerificao = {
  STATUS: boolean;
  MSG: string;
};

@Component
export default class CadastroEvento extends Vue {
  baseUrl = 'https://server.grupopalmed.com.br:4001'
  // baseUrl = 'http://localhost:4002'
  dadosValidos = true;
  posicaoAba = 1;
  alerta = '';
  cnpjcpf = '';
  aguardandoGravacao = false;
  validadorFormulario = {
    vazio: [(v: string) => !!v || 'Campo é Obrigatório'],
    data: [
      (d: string) => !!d || 'Campo é Obrigatório',
      (d: string) => this.validarData(d) || 'Data Inválida'
    ],
    telefone: [(t: string) => this.validarTelefone(t) || 'Telefone Inválido']
  };

  dadosFormulario: DadosFormulario = {
    retorno: false,
    mensagem: '',
    cliente: {
      CODCLI: 0,
      CLIENTE: '',
      TELEFONE: '',
      EMAIL: ''
    },
    responsavel: {
      NOME: '',
      INSTAGRAM: '',
      CELULAR: ''
    }
  };

  exibirAlerta (alerta: string): void {
    this.alerta = alerta
  }

  // Validando Passo 1 do Processo de Cadastro
  async validarPasso1 (): Promise<void> {
    this.exibirAlerta('')
    if (this.cnpjcpf === '') {
      this.exibirAlerta('Informe um CNPJ Válido')
      return
    }
    await fetcher<{
      STATUS: boolean;
      MSG: string;
      DATA: {
        CODCLI: number;
        CLIENTE: string;
        TELEFONE: string;
        EMAIL: string;
      };
    }>(
      'POST',
      `${this.baseUrl}/auth/cliente`,
      {
        cnpj: this.cnpjcpf,
        codevento: 22
      }
    )
      .then((res) => {
        if (res.ok && res.data?.STATUS) {
          this.posicaoAba = 2
          this.dadosFormulario = {
            retorno: res.data?.STATUS,
            mensagem: res.data?.MSG,
            cliente: res.data?.DATA,
            responsavel: {
              NOME: '',
              INSTAGRAM: '',
              CELULAR: ''
            }
          }
        } else {
          this.exibirAlerta(res.data?.MSG)
        }
      })
      .catch((err) => {
        this.exibirAlerta((err as Error).message)
      })
  }

  // Validando Passo 3 do Processo de cadastro
  async validarPasso2 (): Promise<void> {
    this.exibirAlerta('')
    this.posicaoAba = 3
  }

  // Validando Passo 4 do Processo de cadastro
  async validarPasso3 (): Promise<void> {
    this.exibirAlerta('')
    this.dadosFormulario.codigoVerificacao = undefined
    if (this.dadosFormulario.responsavel.NOME === '') {
      this.exibirAlerta('Nome é obrigatório')
      return
    }
    if (this.dadosFormulario.responsavel.CELULAR === '') {
      this.exibirAlerta('Celular é obrigatório')
      return
    }
    if (this.cnpjcpf === '') {
      this.exibirAlerta('CNPJ Válido')
      return
    }
    const res = await fetcher<RetornoMsgVerificao>(
      'POST',
      `${this.baseUrl}/auth/codigoVerificacao`,
      {
        cnpj: this.cnpjcpf,
        nome: this.dadosFormulario.responsavel.NOME,
        celular: this.dadosFormulario.responsavel.CELULAR,
        novocodigo: false
      }
    )
    if (res.data.STATUS) {
      this.posicaoAba = 4
    } else {
      this.exibirAlerta(res.data.MSG)
    }
  }

  // Validando Passo 4
  async validarPasso4 (): Promise<void> {
    this.exibirAlerta('')
    if (this.cnpjcpf === '') {
      this.exibirAlerta('CNPJ Válido')
      return
    }
    if (this.dadosFormulario.codigoVerificacao === undefined || String(this.dadosFormulario.codigoVerificacao) === '') {
      this.exibirAlerta('Código Inválido')
      return
    }
    this.aguardandoGravacao = true
    try {
      const res = await fetcher<RetornoMsgVerificao>(
        'POST',
        `${this.baseUrl}/auth/validarCodigoVerificacao`,
        {
          cnpj: this.cnpjcpf,
          nome: this.dadosFormulario.responsavel.NOME,
          celular: this.dadosFormulario.responsavel.CELULAR,
          codigo: this.dadosFormulario.codigoVerificacao
        }
      )
      console.log({
        cnpj: this.cnpjcpf,
        nome: this.dadosFormulario.responsavel.NOME,
        celular: this.dadosFormulario.responsavel.CELULAR,
        codigo: this.dadosFormulario.codigoVerificacao || 0
      })
      if (res.data.STATUS) {
        // envia o cadastro se tudo ok então finaliza o processo
        await this.validarPasso6().then((retorno: boolean) => {
          if (retorno) {
            this.posicaoAba = 5
          }
        })
      } else {
        this.exibirAlerta(res.data.MSG)
      }
    } finally {
      this.aguardandoGravacao = false
    }
  }

  // Validando Passo 5 Cadastrar o cliente
  async validarPasso6 (): Promise<boolean> {
    this.exibirAlerta('')
    const res = await fetcher<RetornoMsgVerificao>(
      'POST',
      `${this.baseUrl}/auth/novoUsuarioEvento`,
      {
        CODEVENTO: 22,
        CODCLI: this.dadosFormulario.cliente.CODCLI,
        NOME_PARTICIPANTE: this.dadosFormulario.responsavel.NOME,
        INSTAGRAM_PARTICIPANTE: this.dadosFormulario.responsavel.INSTAGRAM,
        CELULAR_PARTICIPANTE: this.dadosFormulario.responsavel.CELULAR
      }
    )
    if (!res.data.STATUS) {
      this.exibirAlerta(res.data.MSG)
      return false
    } else {
      return true
    }
  }

  // validar data
  validarData (dateStr: string): boolean {
    if (dateStr === undefined) {
      return false
    }
    return moment(dateStr, 'DD/MM/YYYY', true).isValid()
  }

  // validar telefone
  validarTelefone (telefone: string): boolean {
    if (telefone || telefone !== '') {
      var regex = new RegExp(
        '^\\([1-9]{2}\\) (?:[2-8]|9[1-9])[0-9]{3}\\-[0-9]{4}$'
      )
      return regex.test(telefone)
    } else {
      return true
    }
  }
}
